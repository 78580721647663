@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

html {
    @apply font-sans antialiased bg-white text-black;
}

body {
    font-family: 'Inter', sans-serif;
}

table .tr-pl-4 {
    padding-left: 0!important;
}


.map-container {
    height: 200px;
    width: 700px;
}

.max-w-8xl {
    max-width: 95rem;
}

.react-resizable {
    max-width: 100%;
}

.react-resizable-handle {
    bottom: -10px;
    right: -10px;
}

.recharts-wrapper svg {
    width: 100% !important;
    height: 100% !important;
}
